<template>
  <div class="hero is-primary is-bold">
    <div class="hero-body">
      <div class="container has-text-centered">
        <h1 class="is-size-2">
          {{ title }}
        </h1>
        <p class="is-size-5">
          {{ msg }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Main",
  props: {
    title: {
      type: String,
      required: true
    },
    msg: {
      type: String,
      required: true
    }
  }
};
</script>

<style>
/* .migrations {
  background-color: rgb(230, 25, 25);
} */
</style>
