<template>
  <!-- Disables links in case the Production/Sandbox mapping does not exist. -->
  <!-- May happen only if the SalesforceAmsMapping database table is out of sync with the Production/Sandbox CSV mapping. -->
  <div v-if="environmentSpecificSalesforceId == null">
    {{ salesforceId }}
  </div>
  <a
    v-else-if="environmentSpecificSalesforceId != null"
    target="_blank"
    :href="salesforceUrl + '/' + environmentSpecificSalesforceId"
  >
    {{ salesforceId }}
  </a>
</template>

<script>
export default {
  props: {
    salesforceId: {
      type: String,
      required: true,
    },
    environmentSpecificSalesforceId: {
      type: String,
      required: false,
      default: null
    },
  },
  data: function () {
    return {
      salesforceUrl: process.env.VUE_APP_SALESFORCE_URL
    };
  },
};
</script>
