<template>
  <b-button
    class="retry-button"
    outlined
    rounded
    size="is-small"
    type="is-primary"
    :loading="loading"
    @click="doRetry"
  >
    Retry
  </b-button>
</template>

<script>
import { restartMigration } from "@/services/HistoryMigrationStatusService";

export default {
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      loading: false
    };
  },
  watch: {
    item: function () {
      // enable button on item refresh
      this.loading = false;
    }
  },
  methods: {
    async doRetry() {
      this.loading = true;

      const result = await restartMigration(this.item.amsVehicleId);

      if (result.error) {
        this.$emit("retry-error", result.error)
      } else {
        this.$emit("retry-success", result.data)
      }

      this.loading = false
    }
  }
};
</script>
