<template>
  <b-navbar>
    <template #start>
      <b-navbar-item
        tag="router-link"
        to="/"
      >
        Start migration
      </b-navbar-item>
      <b-navbar-item
        tag="router-link"
        to="/migrations"
      >
        Migration statuses
      </b-navbar-item>
    </template>

    <template #end>
      <b-navbar-item tag="div">
        <div class="buttons">
          <b-button
            tag="router-link"
            to="/logout"
            type="is-danger is-light"
          >
            Log out
          </b-button>
          <b-button
            tag="router-link"
            to="/login"
            type="is-primary is-light"
          >
            Log in
          </b-button>
        </div>
      </b-navbar-item>
    </template>
  </b-navbar>
</template>

<script>
export default {
  name: "NavBar"
};
</script>
