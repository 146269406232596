<template>
  <section>
    <Main
      title="AMS to RAM Customer Migrations"
      msg="Table of the migration statuses"
    />
    <div class="columns is-centered">
      <div class="column is-11">
        <div class="box">
          <MigrationStatusTable />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Main from "@/components/Main";
import MigrationStatusTable from "@/components/migrationstatus/MigrationStatusTable";

export default {
  components: {
    Main,
    MigrationStatusTable
  }
};
</script>

<style scoped></style>
