import axios from "axios";
import Auth from "../auth/auth";
import { parseAxiosErrorResponse } from "@/response/ErrorResponse";

export const startMigration = async (salesforceId) => {
  try {
    const response = await axios.post(
      "/migrations",
      { salesforceId: salesforceId },
      {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${Auth.getUserToken()}`
        }
      }
    );

    return { data: response.data }
  } catch (error) {
    return { error: parseAxiosErrorResponse(error) }
  }
};
