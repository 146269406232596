import Vue from "vue";

import axios from "axios";
import Buefy from "buefy";
import "buefy/dist/buefy.css";
import router from "./router";

import App from "./App.vue";

axios.defaults.baseURL = process.env.VUE_APP_BACKEND_API_URL;

Vue.use(Buefy);

Vue.config.productionTip = false;

new Vue({
  router,
  render: (h) => h(App)
}).$mount("#app");
