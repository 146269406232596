<template>
  <b-button
    v-if="isFailed"
    class="retry-button"
    outlined
    rounded
    size="is-small"
    type="is-primary"
    :loading="loading"
    @click="doRetry"
  >
    Retry
  </b-button>
</template>

<script>
import { startMigration } from "@/services/MigrationStartService";

export default {
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      loading: false
    };
  },
  computed: {
    isFailed() {
      return this.$props.item.migrationStatus === "FAILED";
    }
  },
  watch: {
    item: function () {
      // enable button on item refresh
      this.loading = false;
    }
  },
  methods: {
    async doRetry() {
      this.loading = true;

      const result = await startMigration(this.item.salesforceId);

      if (result.error) {
        this.$emit("retry-error", result.error)
      } else {
        this.$emit("retry-success", result.data)
      }

      this.loading = false
    }
  }
};
</script>
