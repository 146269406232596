<template>
  <div class="level">
    <div class="level-left" />
    <div class="level-right">
      <b-button
        class="level-item"
        outlined
        rounded
        size="is-small"
        type="is-primary"
        @click="onRefresh"
      >
        <b-icon
          pack="fas"
          icon="sync-alt"
        />
        <span>Refresh</span>
      </b-button>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    onRefresh() {
      this.$emit("refresh-click");
    }
  }
};
</script>
