<template>
  <nav
    class="pagination is-rounded"
    role="navigation"
    aria-label="pagination"
  >
    <b-button
      class="pagination-previous"
      :disabled="!hasPreviousPage"
      @click="previous"
    >
      Previous page
    </b-button>
    <b-button
      class="pagination-next"
      :disabled="!hasNextPage"
      @click="nextClick"
    >
      Next page
    </b-button>
  </nav>
</template>

<script>
export default {
  props: {
    hasPreviousPage: {
      type: Boolean,
      required: false,
      default: false,
    },
    hasNextPage: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  methods: {
    nextClick: function () {
      this.$emit("next-click");
    },
    previous: function () {
      this.$emit("previous-click");
    }
  }
};
</script>
