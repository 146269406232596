<template>
  <b-notification
    :type="notificationType"
    :active.sync="isActive"
    has-icon
    aria-close-label="Close notification"
    role="alert"
  >
    {{ message }}
    <br v-if="reason">
    {{ reason ? "Reason: " + reason : "" }}
  </b-notification>
</template>

<script>
export default {
  name: "NotificationBox",
  props: {
    message: {
      type: String,
      required: true,
    },
    reason: {
      type: String,
      required: false,
      default: null,
    },
    type: {
      type: String,
      required: false,
      validator: function (value) {
        return ["success", "danger"].indexOf(value) >= 0;
      },
      default: null
    }
  },
  data() {
    return {
      isActive: false
    };
  },
  computed: {
    notificationType() {
      return `is-${this.$props.type}`;
    }
  },
  watch: {
    message: function () {
      this.isActive = true
    }
  },
};
</script>
