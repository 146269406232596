<template>
  <a
    target="_blank"
    :href="'https://support2uk.amsfleetmanager.com/tools/imeisearch.aspx?imei=' + imei"
  >{{ imei }}</a>
</template>

<script>
export default {
  props: {
    imei: {
      type: String,
      required: true,
    }
  }
};
</script>
