import Vue from "vue";
import Router from "vue-router";
import auth from "../auth/auth";
import LogoutSuccess from "@/components/LogoutSuccess";
import UserInfoStore from "../auth/user-info-store";
import UserInfoApi from "../auth/user-info-api";
import ErrorComponent from "@/components/Error";
import MigrationStatusContainer from "@/components/migrationstatus/MigrationStatusContainer";
import StartMigrations from "@/components/StartMigrations";

Vue.use(Router);

const routes = [
  {
    path: "/migrations",
    component: MigrationStatusContainer,
    beforeEnter: requireAuth
  },
  {
    path: "/",
    component: StartMigrations,
    beforeEnter: requireAuth
  },
  {
    path: "/login",
    beforeEnter() {
      auth.login();
    }
  },
  {
    path: "/login/oauth2/code/cognito",
    beforeEnter() {
      var currUrl = window.location.href;

      auth.auth.parseCognitoWebResponse(currUrl);
    }
  },
  {
    path: "/logout",
    component: LogoutSuccess,
    beforeEnter(to, from, next) {
      auth.logout();
      next();
    }
  },
  {
    path: "/error",
    component: ErrorComponent
  }
];

function requireAuth(to, from, next) {
  if (process.env.VUE_APP_SKIP_AUTH) {
    next();
  } else if (!auth.auth.isUserSignedIn()) {
    UserInfoStore.setLoggedIn(false);
    next({
      path: "/login",
      query: { redirect: to.fullPath }
    });
  } else {
    UserInfoApi.getUserInfo().then((response) => {
      UserInfoStore.setLoggedIn(true);
      UserInfoStore.setCognitoInfo(response);
      next();
    });
  }
}

export default new Router({
  mode: "history",
  base: "/",
  routes
});
