import axios from "axios";
import Auth from "../auth/auth";

export const findAll = async (pageSize, filters, lastPageRecord) => {
  const lastPageRecordParameter = lastPageRecord !== null ? `&lastPageRecord=${lastPageRecord}` : "";
  const filterParameters = getFilterParameter(filters);
  try {
    const response = await axios.get(
      `/migrations?pageSize=${pageSize}${filterParameters}${lastPageRecordParameter}`,
      {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${Auth.getUserToken()}`
        }
      }
    );
    return { data: response.data, error: null };
  } catch (error) {
    return { error };
  }
};

const getFilterParameter = (filters) => {
  let filterParameter = "";
  for (const key in filters) {
    switch (key) {
      case "salesforceId":
        filterParameter += `&id=${filters[key]}`;
        break;
      case "migrationStatus":
        filterParameter += `&status=${filters[key]}`;
        break;
      case "accountName":
        filterParameter += `&accountName=${filters[key]}`;
        break;
    }
  }
  return filterParameter;
};
