<template>
  <section>
    <NotificationBox
      :message="notificationMessage"
      :reason="notificationReason"
      :type="notificationType"
    />
    <b-table
      :data="data"
    >
      <b-table-column
        v-slot="props"
        field="amsVehicleId"
        label="Asset ID"
        searchable
        sortable
        width="80"
      >
        {{ props.row.amsVehicleId }}
      </b-table-column>

      <b-table-column
        v-slot="props"
        field="ramVehicleId"
        label="Vehicle ID"
        searchable
        sortable
        width="100"
      >
        {{ props.row.ramVehicleId }}
      </b-table-column>

      <b-table-column
        v-slot="props"
        field="errorMessage"
        label="Error Message"
        searchable
        sortable
      >
        {{ props.row.errorMessage }}
      </b-table-column>

      <b-table-column
        v-slot="props"
        field="firstReceivedEventDate"
        label="First received event date"
        searchable
        sortable
      >
        {{ props.row.firstReceivedEventDate }}
      </b-table-column>

      <b-table-column
        v-slot="props"
        field="imei"
        label="IMEI"
        searchable
        sortable
      >
        <ImeiLink :imei="props.row.imei" />
      </b-table-column>

      <b-table-column
        v-slot="props"
        field="hasUnitWithSim"
        label="SIM"
        width="50"
      >
        <b-icon
          v-if="props.row.hasUnitWithSim"
          icon="sim"
          type="is-success"
        />
        <b-icon
          v-else
          icon="sim-off"
          type="is-danger"
        />
      </b-table-column>

      <b-table-column
        v-slot="props"
        field="manualStart"
        label="Manually started"
        width="150"
      >
        {{ props.row.manualStart }}
      </b-table-column>

      <b-table-column
        centered
        field="status"
        label="Status"
        searchable
        sortable
        width="140"
      >
        <template #searchable="props">
          <b-select
            v-model="props.filters[props.column.field]"
          >
            <option :value="null">
              All
            </option>
            <option
              v-for="status in MIGRATION_STATUSES"
              :key="status"
              :value="status"
            >
              {{ status }}
            </option>
          </b-select>
        </template>
        <template #default="props">
          <span
            :class="[
              'tag',
              { 'is-light': props.row.status === MIGRATION_STATUSES.PENDING },
              { 'is-info': props.row.status === MIGRATION_STATUSES.READY },
              { 'is-warning': props.row.status === MIGRATION_STATUSES.STARTED },
              { 'is-success': props.row.status === MIGRATION_STATUSES.COMPLETED },
              { 'is-danger': props.row.status === MIGRATION_STATUSES.FAILED }
            ]"
          >
            {{ props.row.status }}
          </span>
        </template>
      </b-table-column>

      <b-table-column
        v-slot="props"
        field="action"
        label="Actions"
        width="100"
        centered
      >
        <HistoryMigrationRetryButton
          v-if="props.row.status === 'FAILED'"
          :item="props.row"
          @retry-error="showError"
          @retry-success="retrySuccess"
        />
        <HistoryMigrationStartButton
          v-if="(props.row.status === MIGRATION_STATUSES.PENDING && props.row.hasUnitWithSim && !props.row.manualStart) || (props.row.status === MIGRATION_STATUSES.FAILED && (props.row.errorMessage || {}).includes('does not have an odometer value'))"
          :item="props.row"
          @manual-start-error="showError"
          @manual-start-success="startManuallySuccess"
        />
      </b-table-column>
    </b-table>
  </section>
</template>

<script>

import HistoryMigrationRetryButton from "@/components/historystatus/HistoryMigrationRetryButton";
import HistoryMigrationStartButton from "@/components/historystatus/HistoryMigrationStartButton";
import ImeiLink from "@/components/historystatus/ImeiLink";
import NotificationBox from "@/components/NotificationBox";
import Vue from "vue";

export default {
  components: {
    HistoryMigrationStartButton,
    HistoryMigrationRetryButton,
    ImeiLink,
    NotificationBox,
  },
  props: {
    modalData: {
      type: Array,
      required: true,
    }
  },
  data() {
    return {
      data: this.modalData,

      notificationMessage: "",
      notificationReason: null,
      notificationType: "danger",
    }
  },
  created() {
    this.MIGRATION_STATUSES = {
      READY: 'READY',
      STARTED: 'STARTED',
      COMPLETED: 'COMPLETED',
      FAILED: 'FAILED',
      PENDING: 'PENDING',
    };
  },
  methods: {
    showError(event) {
      this.notificationType = "danger";
      this.notificationMessage = event.message;
      this.notificationReason = event.details;
    },
    showSuccess(event, message) {
      // override the existing entry by the retrieved status, to prevent the user from hitting the retry button again
      Vue.set(this.data,
          this.data.findIndex(data => data.amsVehicleId === event.amsVehicleId),
          event
      )

      this.notificationType = "success";
      this.notificationMessage = message;
      this.notificationReason = null
    },
    startManuallySuccess(event) {
      this.showSuccess(event, `Start migration manually request for AMS Asset ID '${event.amsVehicleId}' has succeeded.`)
    },
    retrySuccess(event) {
      this.showSuccess(event, `Retry migration request for AMS Asset ID '${event.amsVehicleId}' has succeeded.`)
    },
  }
}
</script>
