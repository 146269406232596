<template>
  <section>
    <b-modal
      v-model="isModalActive"
      width="85%"
    >
      <HistoryMigrationTable
        :modal-data="modalData"
      />
    </b-modal>
    <NotificationBox
      :message="notificationMessage"
      :reason="notificationReason"
      :type="notificationType"
    />
    <MigrationStatusTableHeader @refresh-click="onRefresh" />
    <b-table
      backend-filtering
      backend-pagination
      backend-sorting
      :striped="true"
      :narrowed="true"
      :hoverable="true"
      :mobile-cards="true"
      :data="data"
      :loading="loading"
      @filters-change="onFiltersChange"
    >
      <b-table-column
        v-slot="props"
        field="salesforceId"
        label="Salesforce ID"
        width="140"
        searchable
      >
        <SalesforceLink
          :salesforce-id="props.row.customerStatus.salesforceId"
          :environment-specific-salesforce-id="props.row.environmentSpecificSalesforceId"
        />
      </b-table-column>

      <b-table-column
        v-slot="props"
        field="accountName"
        label="Account Name"
        width="140"
        searchable
      >
        {{ props.row.customerStatus.accountName }}
      </b-table-column>

      <b-table-column
        field="migrationStatus"
        label="Migration Status"
        width="140"
        centered
        searchable
      >
        <template #searchable="props">
          <b-select
            v-model="props.filters[props.column.field]"
            placeholder="Select a status"
          >
            <option :value="null">
              All
            </option>
            <option
              v-for="status in MIGRATION_STATUSES"
              :key="status"
              :value="status"
            >
              {{ status }}
            </option>
          </b-select>
        </template>
        <template #default="props">
          <span
            :class="[
              'tag',
              { 'is-info': props.row.customerStatus.migrationStatus === MIGRATION_STATUSES.READY },
              { 'is-warning': props.row.customerStatus.migrationStatus === MIGRATION_STATUSES.STARTED },
              { 'is-success': props.row.customerStatus.migrationStatus === MIGRATION_STATUSES.COMPLETED },
              { 'is-danger': props.row.customerStatus.migrationStatus === MIGRATION_STATUSES.FAILED }
            ]"
          >
            {{ props.row.customerStatus.migrationStatus }}
          </span>
        </template>
      </b-table-column>

      <b-table-column
        v-slot="props"
        field="errorMessage"
        label="Error Message"
      >
        {{ props.row.customerStatus.errorMessage }}
      </b-table-column>

      <b-table-column
        v-slot="props"
        field="action"
        label="Actions"
        width="100"
        centered
      >
        <MigrationRetryButton
          :item="props.row.customerStatus"
          @retry-error="showError"
          @retry-success="showSuccess"
        />
      </b-table-column>

      <b-table-column
        v-slot="props"
        centered
        label="History Migration"
        width="160"
      >
        <b-button
          v-show="props.row.historyStatus"
          icon-right="cursor-pointer"
          :class="[
            'tag',
            { 'is-info': props.row.historyStatus === 'ONGOING' },
            { 'is-success': props.row.historyStatus === 'COMPLETED' },
            { 'is-danger': props.row.historyStatus === 'FAILED' }
          ]"
          @click="showModal(props.row.vehicleStatuses)"
        >
          {{ props.row.historyStatus }}
        </b-button>
      </b-table-column>

      <template #footer>
        <SimplePagination
          :has-previous-page="hasPreviousPage()"
          :has-next-page="hasNextPage()"
          @previous-click="onPreviousClick"
          @next-click="onNextClick"
        />
      </template>
    </b-table>
  </section>
</template>

<script>
import {findAll} from "@/services/MigrationStatusService";
import HistoryMigrationTable from "@/components/historystatus/HistoryMigrationTable";
import MigrationRetryButton from "@/components/migrationstatus/MigrationRetryButton";
import MigrationStatusTableHeader from "@/components/migrationstatus/MigrationStatusTableHeader";
import NotificationBox from "@/components/NotificationBox";
import SalesforceLink from "@/components/migrationstatus/SalesforceLink";
import SimplePagination from "@/components/SimplePagination";
import Vue from "vue";

export default {
  components: {
    HistoryMigrationTable,
    MigrationRetryButton,
    MigrationStatusTableHeader,
    NotificationBox,
    SalesforceLink,
    SimplePagination,
  },
  data() {
    return {
      data: [],
      loading: false,
      filters: {},
      pageSize: 20,
      lastPageRecords: [],
      notificationMessage: "",
      notificationReason: null,
      notificationType: "danger",
      isModalActive: false,
      modalData: []
    };
  },
  created() {
    this.MIGRATION_STATUSES = {
      READY: 'READY',
      STARTED: 'STARTED',
      COMPLETED: 'COMPLETED',
      FAILED: 'FAILED',
    };
  },
  mounted() {
    this.loadAsyncData();
  },
  methods: {
    async loadAsyncData() {
      this.loading = true;

      const lastPageRecord =
        this.lastPageRecords.length > 0 ? this.lastPageRecords[this.lastPageRecords.length - 1] : null;

      const response = await findAll(this.pageSize, this.filters, lastPageRecord);

      if (response.error === null) {
        this.data = response.data;
      } else {
        this.showError({message: response.error});
      }

      this.loading = false;
    },
    hasPreviousPage() {
      return this.lastPageRecords.length > 0
    },
    hasNextPage() {
      return this.data.length >= this.pageSize
    },
    onPreviousClick() {
      this.lastPageRecords.pop()

      this.loadAsyncData();
    },
    onNextClick() {
      if (this.data.length > 0) {
        this.lastPageRecords.push(this.data[this.data.length - 1].customerStatus.salesforceId);
      }

      this.loadAsyncData()
    },
    onFiltersChange(filters) {
      for (const key in filters) {
        // remove key if empty
        if (!filters[key]) {
          delete this.filters[key];
          continue;
        }
        this.filters[key] = filters[key];
      }
      this.lastPageRecords = [];
      this.loadAsyncData();
    },
    onRefresh() {
      if (this.lastPageRecords.length > 0) {
        this.lastPageRecords.splice(this.lastPageRecords.length - 1, 1);
      }
      this.loadAsyncData();
    },
    showModal(data) {
      this.modalData = data;
      this.isModalActive = true;
    },
    showError(event) {
      this.notificationType = "danger";
      this.notificationMessage = event.message;
      this.notificationReason = event.details;
    },
    showSuccess(event) {
      // override the existing entry by the retrieved status, to prevent the user from hitting the retry button again
      const restartedMigrationIndex = this.data.findIndex(data => data.customerStatus.salesforceId === event.salesforceId)

      if (restartedMigrationIndex >= 0) {
        const restartedMigration = this.data[restartedMigrationIndex]
        restartedMigration.customerStatus = event

        Vue.set(this.data, restartedMigrationIndex, restartedMigration)
      }

      this.notificationType = "success";
      this.notificationMessage = `Retry migration request for Salesforce ID '${event.salesforceId}' has succeeded.`;
      this.notificationReason = null
    }
  }
};
</script>

<style lang="css" scoped>
.tag {
  min-width: 8rem;
}
</style>
