<template>
  <b-button
    class="retry-button"
    outlined
    rounded
    size="is-small"
    type="is-primary"
    :loading="loading"
    @click="confirm"
  >
    Start manually
  </b-button>
</template>

<script>
import {manualStartMigration} from "@/services/HistoryMigrationStatusService";

export default {
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      loading: false
    };
  },
  watch: {
    item: function () {
      // enable button on item refresh
      this.loading = false;
    }
  },
  methods: {
    async start() {
      this.loading = true;

      const result = await manualStartMigration(this.item.amsVehicleId);

      if (result.error) {
        this.$emit("manual-start-error", result.error)
      } else {
        this.$emit("manual-start-success", result.data)
      }

      this.loading = false
    },
    confirm() {
      this.$buefy.dialog.confirm({
        title: `Start migration manually`,
        message: `Are you sure you want to <b>start migration manually</b> for asset ID <i>${this.item.amsVehicleId}</i>? This action cannot be undone.`,
        type: 'is-danger',
        hasIcon: true,
        cancelText: "Cancel",
        confirmText: "Start migration",
        onConfirm: () => this.start()
      });
    }
  }
};
</script>
