import { CognitoAuth, StorageHelper } from "amazon-cognito-auth-js";
import IndexRouter from "../router/index";
import UserInfoStore from "./user-info-store";
import UserInfoApi from "./user-info-api";

const APP_URL = process.env.VUE_APP_FRONTEND_BASE_URL;
const CLIENT_ID = process.env.VUE_APP_COGNITO_CLIENT_ID;
const APP_DOMAIN = process.env.VUE_APP_COGNITO_APP_DOMAIN;
const USERPOOL_ID = process.env.VUE_APP_COGNITO_USERPOOL_ID;
const REDIRECT_URI = APP_URL + process.env.VUE_APP_COGNITO_REDIRECT_URI;
const REDIRECT_URI_SIGNOUT = APP_URL + process.env.VUE_APP_COGNITO_REDIRECT_URI_SIGNOUT;

const authData = {
  ClientId: CLIENT_ID, // Your client id here
  AppWebDomain: APP_DOMAIN,
  TokenScopesArray: ["openid", "email"],
  RedirectUriSignIn: REDIRECT_URI,
  RedirectUriSignOut: REDIRECT_URI_SIGNOUT,
  UserPoolId: USERPOOL_ID
};

const auth = new CognitoAuth(authData);
auth.userhandler = {
  onSuccess: function () {
    UserInfoStore.setLoggedIn(true);
    UserInfoApi.getUserInfo().then(() => {
      IndexRouter.push("/");
    });
  },
  onFailure: function (err) {
    UserInfoStore.setLoggedOut();
    IndexRouter.go({ path: "/error", query: { message: "Login failed due to " + err } });
  }
};

function getUserInfoStorageKey() {
  const keyPrefix = "CognitoIdentityServiceProvider." + auth.getClientId();
  const tokenUserName = auth.signInUserSession.getAccessToken().getUsername();
  const userInfoKey = keyPrefix + "." + tokenUserName + ".userInfo";
  return userInfoKey;
}

function getUserToken() {
  return auth.getSignInUserSession().getIdToken().jwtToken;
}

const storageHelper = new StorageHelper();
const storage = storageHelper.getStorage();
export default {
  auth: auth,
  login() {
    auth.getSession();
  },
  logout() {
    if (auth.isUserSignedIn()) {
      var userInfoKey = this.getUserInfoStorageKey();
      auth.signOut();

      storage.removeItem(userInfoKey);
    }
  },
  getUserInfoStorageKey,
  getUserToken
};
