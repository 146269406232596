import axios from "axios";
import Auth from "../auth/auth";
import { parseAxiosErrorResponse } from "@/response/ErrorResponse";

export const restartMigration = async (amsAssetId) => {
  try {
    const response = await axios.put(
      `/history-migrations/${amsAssetId}/retry`,
      null,
      {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${Auth.getUserToken()}`
        }
      }
    );

    return { data: response.data }
  } catch (error) {
    return { error: parseAxiosErrorResponse(error) }
  }
};

export const manualStartMigration = async (amsAssetId) => {
  try {
    const response = await axios.put(
      `/history-migrations/${amsAssetId}/manual-start`,
      null,
      {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${Auth.getUserToken()}`
        }
      }
    );

    return { data: response.data }
  } catch (error) {
    return { error: parseAxiosErrorResponse(error) }
  }
};
