<template>
  <div>
    <Main
      title="AMS to RAM Customer Migrations"
      msg="Enter the Salesforce ID of the customer you wish to migrate:"
    />
    <div
      id="MigrationForm"
      class="m-t-30"
    >
      <div class="columns is-centered">
        <div class="column is-8">
          <div class="box">
            <NotificationBox
              :message="notificationMessage"
              :reason="notificationReason"
              :type="notificationType"
            />

            <form
              class="container"
              @submit.prevent="postEndpoint"
            >
              <b-field label="Salesforce ID:">
                <b-input
                  v-model.trim="salesforceId"
                  placeholder="Salesforce ID..."
                />
              </b-field>
              <b-button
                class="button is-primary"
                :disabled="isDisabled"
                :loading="loading"
                @click.prevent="postEndpoint"
              >
                Start Migration
              </b-button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Main from "@/components/Main";
import NotificationBox from "@/components/NotificationBox";
import {startMigration} from "@/services/MigrationStartService";

export default {
  name: "StartMigrations",
  components: {
    Main,
    NotificationBox,
  },
  data() {
    return {
      salesforceId: "",
      loading: false,
      notificationMessage: "",
      notificationReason: null,
      notificationType: "danger",
    };
  },
  computed: {
    isDisabled() {
      return !this.salesforceId;
    }
  },
  methods: {
    async postEndpoint() {
      this.loading = true

      const result = await startMigration(this.salesforceId)

      if (result.error) {
        this.showError(result.error.message, result.error.details)
      } else {
        this.showSuccess(`The start migration request for Salesforce ID '${result.data.salesforceId}' has succeeded.`)
      }

      this.loading = false
    },
    showError(message, reason) {
      this.notificationType = "danger";
      this.notificationMessage = message;
      this.notificationReason = reason;
    },
    showSuccess(message) {
      this.notificationType = "success";
      this.notificationMessage = message;
      this.notificationReason = null
    },
  }
};
</script>
