<template>
  <section>
    <b-message
      aria-close-label="Close message"
      title="Info"
      type="is-danger"
    >
      {{ message }}
    </b-message>
  </section>
</template>
<script>
export default {
  data: function () {
    return {
      message: ""
    };
  },
  mounted() {
    this.message = this.$route.query.message;
  }
};
</script>
