export function parseAxiosErrorResponse(axiosResponse) {
    const responseData = axiosResponse?.response?.data

    if (responseData) {
        return {
            message: responseData?.message,
            details: responseData?.details,
        }
    }

    // If the server did not return any error data (i.e. when Axios fails to contact the backend for example),
    // fallback to Axios client error message.
    return {
        message: "Failed to process the request",
        details: axiosResponse.message,
    }
}
