<template>
  <section>
    <b-message
      aria-close-label="Close message"
      title="Logged Out"
      type="is-info"
    >
      Logged Out successfully.
    </b-message>
  </section>
</template>

<script>
export default {
  mounted: function () {}
};
</script>
